<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bağımsız Bölüm Özel Gelir Türü Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-text-field
              label="Adı"
              v-model="formData.name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="formData.is_active"
              :label="formData.is_active ? $t('active') : $t('passive')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";
import ClusterService from "@/core/services/cluster.service";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "HousingIncomeForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["cluster"]),
  },
  methods: {
    show(formData) {
      this.formData = formData
        ? Object.assign({}, formData)
        : {
            parent_id: 10,
            is_active: true,
          };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      if (formData.id) {
        this.$api
          .put(`/income-types/${formData.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            if (event && event.closeOnSave) {
              this.hide();
              ClusterService.loadIncomeTypeList();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.cluster.id}/income-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            if (event && event.closeOnSave) {
              this.hide();
              ClusterService.loadIncomeTypeList();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
