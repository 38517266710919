<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bağımsız Bölüm Özel Gelir Türleri"
            :add-route="can('edit-custom-income-type') ? '#' : null"
            @click:add="handleAddClick"
            :hide-edit="!can('edit-custom-income-type')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            icon="mdi-home-group-plus"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :search.sync="search.query"
            :show-delete="can('delete-custom-income-type')"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleBatchDeleteClick"
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-btn text icon @click="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
      </v-data-table>

      <HousingIncomeForm ref="housingIncomeForm" @saved="handleFormSaved" />
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir yönetim şirketi seçin.
    </v-card-text>

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteConfirmed"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import ClusterService from "@/core/services/cluster.service";
import { filtersToURL, hasPermissions } from "@/view/mixins";
import HousingIncomeForm from "@/view/pages/income/forms/HousingIncomeForm";

export default {
  name: "HousingIncomeList",
  mixins: [filtersToURL, hasPermissions],
  components: {
    HousingIncomeForm,
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      options: {
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-custom-income-type"),
        },
        {
          text: "Adı",
          value: "name",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;
      params.parent_id = 10;

      this.$api
        .query(`clusters/${this.clusterId}/income-types`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.housingIncomeForm.show();
    },
    handleFormSaved() {
      this.loadList();
    },
    handleEditClick(item) {
      if (item.id) {
        this.$refs.housingIncomeForm.show(item);
      } else if (this.selectedItems.length === 1) {
        this.$refs.housingIncomeForm.show(this.selectedItems[0]);
      }
    },
    handleDelete() {
      this.$api
        .delete(`income-types/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Bağımsız bölüm özel gelir türü silindi.");
          ClusterService.loadIncomeTypeList();
          this.$nextTick(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} bağımsız bölüm özel gelir türünü silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      this.handleDelete();
      this.$refs.confirmDelete.hide();
    },
  },
};
</script>
